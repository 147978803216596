import styles from './HeroImage.module.css';

function HeroImage() {
  return (
    <div className='an-relative an-mb-[36px] an-ml-[36px]'>
      <div className={styles.heroImageSpotlight}>
      </div>
      <div className="an-z-10 an-relative an-flex an-justify-center an-items-center an-max-w-[180px] md:an-max-w-[280px] lg:an-max-w-none">
        <img src="/img/hero-graphic-animesh.webp" width={370} height={709} />
      </div>
    </div>
  )
}

export default HeroImage;