import ProjectDescription from "../ProjectDescription/ProjectDescription";
import ProjectWrapper from "../ProjectsWrapper/ProjectsWrapper";

function WebAR() {
  return (
    <ProjectWrapper className="an-bg-saturated-blue-accent sm:an-w-full md:an-w-[50%] md:first:an-mr-[28px]" linkUrl="https://react-projects-animeshk874.vercel.app/web-ar">
      <div className="an-flex an-items-center md:an-flex-col 2md:an-flex-row an-flex-col sm:an-flex-row">
        <div className="an-ml-[8px] sm:an-ml-[8px] md:an-ml-0 2md:an-ml-[8px] lg:an-mr-[28px] xl:an-mx-[28px] 2md:an-mr-[12px]">
          <ProjectDescription 
            title="Astronaut in AR" 
            body="No worries if you can't go and meet an astronaut. You can always bring the astronaut to your backyard." 
            titleClassName="an-text-white an-mb-[8px]"
            bodyClassName="an-text-white sm:an-max-w-[480px] md:an-max-w-none"
          />
        </div>
        <div className="an-flex an-justify-end xl:an-mr-[36px]">
          <div className="xl:an-w-[220px] lg:an-w-[170px] 2md:an-w-[150px] md:an-w-[220px] an-w-[150px] 2md:an-mt-0  an-mt-[24px]">
            <img src="/img/astronaut.png" className="an-w-full" width={223} height={289} />
          </div>
        </div>
      </div>
    </ProjectWrapper>
  );
}

export default WebAR;