import clsx from "clsx";
import { SOCIAL_MEDIA_LINKS } from "../../../../models/constants/SocialMedia";
import { SocialMedia } from "../../../../models/types/SocialMedia.types";
import Button from "../../../Common/ButtonComponent";

function HeroSocialMedia() {
  return (
    <div className="an-mt-[36px] md:an-mt-[64px] lg:an-mt-[60px] an-flex an-justify-center sm:an-justify-start">
      <div className="an-flex an-ml-2 2md:an-ml-3">
        {
          SOCIAL_MEDIA_LINKS.map((socialMediaItem: SocialMedia) => {
            return <SocialMediaButton socialMediaItem={socialMediaItem} key={socialMediaItem.name} />;
          })
        }
      </div>
    </div>
  )
}

function SocialMediaButton({
  socialMediaItem
}: { socialMediaItem: SocialMedia }) {
  return (
    <a className={clsx(
        "an-flex an-items-center an-justify-center an-p-[9px]", 
        "an-bg-neutral-800 an-rounded-[50%]", 
        "an-h-[44px] an-w-[44px] md:an-h-[52px] md:an-w-[52px] 2md:an-h-[60px] 2md:an-w-[60px] an-mx-2 2md:an-mx-3 an-cursor-default",
        "hover:an-translate-y-[-5px] hover:an-shadow-xl hover:!an-shadow-neutral-200",
        "an-transition an-duration-300 an-ease-in-out"
      )}
      href={socialMediaItem.link}
      target="_blank"
    >
      <img src={`/img/${socialMediaItem.icon}`} alt={socialMediaItem.name} />
    </a>
  )
}

export default HeroSocialMedia;