import { memo } from "react"
import HeroImage from "./HeroImage/HeroImage";
import HeroSocialMedia from "./HeroSocialMedia/HeroSocialMedia";
import HeroText from "./HeroText/HeroText";

function HeroSectionComponent() {
  return (
    <div className="an-flex an-flex-col-reverse sm:an-flex-row an-justify-between an-items-center an-mb-16">
      <div className="an-flex an-flex-col flex-wrap an-max-w-[420px] lg:an-max-w-[560px] xl:an-max-w-[650px] an-mr-[30px]">
        <HeroText />
        <HeroSocialMedia />
      </div>
      <div className="sm:an-mr-[12px] md:an-mr-[40px] lg:an-mr-[80px]">
        <HeroImage />
      </div>
    </div>

  )
}

export default memo(HeroSectionComponent);