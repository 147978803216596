import ProjectDescription from "../ProjectDescription/ProjectDescription";
import ProjectWrapper from "../ProjectsWrapper/ProjectsWrapper";

function CSSImages() {
  return (
    <ProjectWrapper className="an-bg-dark-gray-accent sm:an-w-full md:an-w-[45%] 2md:an-w-[35%] first:md:an-mr-[28px] an-mb-[28px] md:an-mb-0" linkUrl="https://codepen.io/collection/AORBNa">
      <ProjectDescription 
        title="Daily CSS Images" 
        body="A 50-day challenge to make an illustration each day using CSS." 
        titleClassName="an-text-white"
        bodyClassName="an-text-gray-300"
      />
      <div className="an-flex an-justify-end">
        <div className="an-mt-[48px] an-w-full">
          <img src="/img/vampire.png" className="an-w-full an-max-w-[400px] md:an-max-w-none an-mx-auto" width={456} height={329} />
        </div>
      </div>
    </ProjectWrapper>
  );
}

export default CSSImages;