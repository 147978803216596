import { Fragment } from "react";
import clsx from "clsx";
import { HighlightTextType } from "../../../../models/types/HighlightedText.types";
import HighlightedText from "../../../Common/HighlightedText";

const HERO_HEADING_DEFAULT_CLASSES = "an-text-[34px] md:an-text-[42px] 2md:an-text-[50px] lg:an-text-[64px] xl:an-text-[80px] !an-leading-[38px] md:!an-leading-[46px] 2md:!an-leading-[60px] lg:!an-leading-[70px] xl:!an-leading-[80px] an-font-bold";
const HERO_TEXT_DEFAULT_CLASSES = "an-text-[16px] md:an-text-[18px] 2md:an-text-[24px] an-leading-[18px] md:an-leading-[24px] 2md:!an-leading-[36px] an-font-regular an-text-gray-800 xl:an-text-[28px]";

function HeroText() {
  return (
    <Fragment>
      <div className="an-mb-[16px] md:an-mb-[28px] an-text-center sm:an-text-left">
        <span className={clsx("an-text-black", HERO_HEADING_DEFAULT_CLASSES)}>Hello! I am </span>
        <div>
          <HighlightedText 
            highlightType={HighlightTextType.BLUE_GRADIENT} 
            customHighlightClass={HERO_HEADING_DEFAULT_CLASSES} 
          >
            Animesh Kumar
          </HighlightedText>
        </div>
      </div>
      <div className="an-text-center sm:an-text-left">
        <span className={clsx(HERO_TEXT_DEFAULT_CLASSES)}>I am a&nbsp;</span>
        <HighlightedText 
          highlightType={HighlightTextType.BOLD} 
          customHighlightClass={HERO_TEXT_DEFAULT_CLASSES} 
        >
          Frontend Engineer
        </HighlightedText>
        <span className={clsx(HERO_TEXT_DEFAULT_CLASSES)}>
          &nbsp;and I have been building performant and delightful web apps for over&nbsp;
        </span>
        <HighlightedText 
          highlightType={HighlightTextType.BOLD} 
          customHighlightClass={HERO_TEXT_DEFAULT_CLASSES} 
        >
          6 years.
        </HighlightedText>
      </div>
    </Fragment>
  );
}

export default HeroText;