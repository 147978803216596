import { Fragment } from 'react';
import './App.css';
import HomeComponent from './components/HomeComponent/HomeComponent';

function App() {
  return (
    <Fragment>
      <HomeComponent />
    </Fragment>
  );
}

export default App;
