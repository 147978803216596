export enum HighlightTextType {
    BLUE_GRADIENT='blue-gradient',
    BOLD='bold',
    UNDERLINE='underline'
}

export interface HighlightTextProps {
    children: string;
    highlightType?: HighlightTextType;
    customHighlightClass?: string;
}
