import { SocialMedia } from "../types/SocialMedia.types";

export const SOCIAL_MEDIA_LINKS: SocialMedia[] = [
  {
    name: 'LinkedIn',
    link: 'https://www.linkedin.com/in/animeshk874/',
    icon: 'linkedin.svg'
  },
  {
    name: 'Twitter',
    link: 'https://twitter.com/animeshk874',
    icon: 'x.svg'
  },
  {
    name: 'Github',
    link: 'https://github.com/animeshk874',
    icon: 'github.svg'
  }
];