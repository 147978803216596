import ProjectDescription from "../ProjectDescription/ProjectDescription";
import ProjectWrapper from "../ProjectsWrapper/ProjectsWrapper";

function Dryft() {
  return (
    <ProjectWrapper className="an-bg-violet-accent an-w-full md:an-w-[50%] first:md:an-mr-[28px]" linkUrl="https://chrome.google.com/webstore/detail/dryft/bpfibgbdeacmameoklggiabcekheipei?hl=en">
      <ProjectDescription 
        title="Dryft" 
        body="Dryft is a free Google Chrome extension that monitors the time you spend on your favourite websites." 
        titleClassName="an-text-white"
        bodyClassName="an-text-gray-200"
      />
      <div className="an-flex an-justify-end">
        <div className="an-mt-[36px] an-w-[calc(100%+28px)] an-mr-[-28px]">
          <img src="/img/dryft-clay-mock.png" className="an-w-full" width={581} height={414} />
        </div>
      </div>
    </ProjectWrapper>
  );
}

export default Dryft;