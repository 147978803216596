import clsx from "clsx";
import { memo } from "react";
import { DEFAULT_HIGHLIGHT_TEXT_PROPS } from "../../models/constants/HighlightText";
import { HighlightTextProps, HighlightTextType } from "../../models/types/HighlightedText.types";
import styles from './HighlightedText.module.css';

function HighlightedText({
  children,
  highlightType,
  customHighlightClass
}: HighlightTextProps = DEFAULT_HIGHLIGHT_TEXT_PROPS) {
  return (
    <span
      className={clsx(
        {
          [styles.highlightedTextBlueGradient]: highlightType == HighlightTextType.BLUE_GRADIENT,
          '!an-font-bold': highlightType == HighlightTextType.BOLD,
          '!an-text-decoration-line': highlightType == HighlightTextType.UNDERLINE,
        },
        customHighlightClass
      )}
    >
      {children}
    </span>
  )
}

export default memo(HighlightedText);