import ProjectDescription from "../ProjectDescription/ProjectDescription";
import ProjectWrapper from "../ProjectsWrapper/ProjectsWrapper";

function NeumorphicToggle() {
  return (
    <ProjectWrapper className="an-bg-saturated-yellow-accent sm:an-w-full md:an-w-[55%] 2md:an-w-[65%] first:an-mr-[28px]" linkUrl="https://codepen.io/animeshk874/full/ExKWOgd">
      <div className="an-flex an-items-center an-flex-col-reverse 2md:an-flex-row">
        <div className="an-flex an-justify-end 2md:an-mr-[12px] lg:an-mr-[24px] xl:an-mr-[36px]">
          <div className="an-mt-[12px] an-w-[280px] xl:an-mt-[-28px] xl:an-w-[350px] an-mb-[-28px] overflow-hidden">
            <img src="/img/clay-iphone.png" className="an-hidden 2md:an-inline an-w-full" width={581} height={414} />
            <img src="/img/iphone-small.png" className="an-inline 2md:an-hidden an-w-full an-mb-[-340px] an-mt-[24px]" width={478} height={936} />
          </div>
        </div>
        <div>
          <ProjectDescription 
            title="Neumorphic Toggle" 
            body="A neumorphic toggle element for your website that would enhance the user experience and feel of your app " 
            titleClassName="an-text-white an-mb-[6px]"
            bodyClassName="an-text-white"
          />
        </div>
      </div>
    </ProjectWrapper>
  );
}

export default NeumorphicToggle;