import clsx from "clsx";
import { Fragment } from "react"

function ProjectDescription({
  title,
  body,
  titleClassName,
  bodyClassName
}: {
  title: string,
  body: string,
  titleClassName: string,
  bodyClassName: string
}) {
  return (
    <Fragment>
      <p className={clsx(
        'an-font-semibold an-text-[20px] sm:an-text-[24px] 2md:an-text-[28px] an-leading-[36px]',
        titleClassName
      )}>{title}</p>
      <p className={clsx(
        'an-font-400 sm:an-text-[16px] 2md:an-text-[18px] an-leading-[24px]',
        bodyClassName
      )}>{body}</p>
    </Fragment>
  )
}

export default ProjectDescription;