import clsx from "clsx";

function ProjectWrapper({
  className,
  children,
  linkUrl = ''
}: {
  className: string;
  children: React.ReactNode,
  linkUrl?: string,
}) {
  return (
    <div className={clsx(
      className,
      'an-rounded-[20px] an-overflow-hidden',
      'hover:an-outline hover:an-outline-light-blue-primary-20 an-outline-offset-4'
    )}>
      <a className="an-block an-p-[18px] sm:an-p-[24px] 2md:an-p-[28px] an-cursor-default" href={linkUrl} target="_blank">
        {children}
      </a>
    </div>
  )
}

export default ProjectWrapper;