import ProjectDescription from "../ProjectDescription/ProjectDescription";
import ProjectWrapper from "../ProjectsWrapper/ProjectsWrapper";

function MemoryMatch() {
  return (
    <ProjectWrapper className="an-bg-light-blue-accent sm:an-w-full md:an-w-[50%] first:md:an-mr-[28px] an-mb-[28px] md:an-mb-0" linkUrl="https://react-projects-animeshk874.vercel.app/memory-matching">
      <ProjectDescription 
        title="Memory Match" 
        body="A memory match game made using React and 🧡" 
        titleClassName="an-text-gray-700"
        bodyClassName="an-text-gray-500"
      />
      <div className="an-mt-[20px] an-w-full">
        <img src="/img/memory-match.png" className="an-w-full" width={501} height={504} />
      </div>
    </ProjectWrapper>
  );
}

export default MemoryMatch;