import { Fragment, memo } from "react";
import ProjectsSection from "../ProjectsSection/ProjectsSection";
import HeroSection from "./HeroSection/HeroSection";

function HomeComponent() {
    return (
        <Fragment>
          <div className="width-wrapper">
            <HeroSection />
            <ProjectsSection />
          </div>
        </Fragment>
    )
}

export default memo(HomeComponent);