import clsx from "clsx";
import React from "react";
import { SECTION_HEADING_CLASSES } from "../../models/constants/constants";
import { HighlightTextType } from "../../models/types/HighlightedText.types";
import HighlightedText from "../Common/HighlightedText";
import CSSImages from "./CSSImages/CSSImages";
import Dryft from "./Dryft/Dryft";
import MemoryMatch from "./MemoryMatch/MemoryMatch";
import NeumorphicToggle from "./NeumorphicToggle/NeumorphicToggle";
import WebAR from "./WebAR/WebAr";

function ProjectsSection() {
  return (
    <div>
      <div className="an-mb-[24px] sm:an-mb-[36px] 2md:an-mb-[44px] 2md:an-mb-[56px] an-max-w-[440px] sm:an-max-w-none md:an-max-w-[720px] xl:an-max-w-[950px]">
        <span className={clsx("an-text-black", SECTION_HEADING_CLASSES)}>Projects you should </span>
        <span>
          <HighlightedText 
            highlightType={HighlightTextType.BLUE_GRADIENT} 
            customHighlightClass={SECTION_HEADING_CLASSES} 
          >
            definitely
          </HighlightedText>
          <span className="an-hidden sm:an-inline"><br /></span>
        </span>
        <span className={clsx("an-text-black", SECTION_HEADING_CLASSES)}> check out.</span>
      </div>
      <div>
        <div className="an-flex an-flex-wrap md:an-flex-nowrap an-justify-between an-mb-[28px]">
          <MemoryMatch />
          <Dryft />
        </div>
        <div className="an-flex an-flex-wrap md:an-flex-nowrap an-justify-between an-mb-[28px]">
          <CSSImages />
          <NeumorphicToggle />
        </div>
        <div className="an-flex an-flex-wrap md:an-flex-nowrap an-justify-between an-mb-[28px]">
          <WebAR />
        </div>
      </div>
    </div>
  );
}

export default React.memo(ProjectsSection);